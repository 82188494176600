import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';
import { chain } from 'mathjs';
import { dateFormat, productTypes, onboardSteps, dateTimeFormat, modules } from './types';
import { sortItems } from 'vuetify/es5/util/helpers';
import constants from '@/utils/constants';
import * as Sentry from '@sentry/vue';
import { formatCurrency } from './format';

export function countDays(from, to) {
  return moment(to)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .diff(moment(from).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), 'days');
}

export function openChildWindow(url, title, w, h) {
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  return window.open(
    url,
    title,
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
      w +
      ', height=' +
      h +
      ', top=' +
      top +
      ', left=' +
      left,
  );
}

export function round(val) {
  return Math.round(val);
}

export function fromCents(val) {
  if (!val) val = 0;
  if (isNaN(val)) val = 0;
  return chain(val).divide(100).round(2).done();
}

export function toCents(val) {
  if (!val) val = 0;
  if (isNaN(val)) val = 0;
  return chain(val).multiply(100).round().done();
}

export function fromPercentage(val) {
  if (!val) val = 0;
  if (isNaN(val)) val = 0;
  return chain(val).divide(100).round(2).done();
}

/**
 * @param {?number} val
 * @param {{ decimals?: number, currency?: string, hideZero?: boolean }} config
 * @returns {string}
 */
export function formatMoney(val, { decimals = 2, currency = null, hideZero = false } = {}) {
  if (!val) val = 0;
  if (isNaN(val)) val = 0;

  if (hideZero && val === 0) return '';

  const formatted = chain(val)
    .divide(100)
    .done()
    .toFixed(decimals)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',');

  if (currency) return `${formatted} ${formatCurrency(currency)}`;

  return formatted;
}

export function formatDate(date, format = dateFormat) {
  if (date) {
    return moment(date).format(format);
  }
  return null;
}

export function formatDateTime(date) {
  return moment(date).format(dateTimeFormat);
}

export function formatShortDateTime(date) {
  return moment(date).format('MMM DD, HH:mm');
}

export function firstCharUppercase(val) {
  return val.charAt(0).toUpperCase() + val.slice(1);
}

export function formatPhone(val) {
  try {
    const phoneNumber = parsePhoneNumber(val, 'NO');
    return phoneNumber.format('NATIONAL');
  } catch (e) {
    return val;
  }
}

export function invoiceStatus(i) {
  const dueInDays = countDays(undefined, i.dueDate);

  if (i.isSettled) {
    return {
      text: i.amountCredited === i.gross ? 'Kreditert' : 'Oppgjort',
      subtext: formatDate(i.settledAt),
      color: 'success',
      textColor: 'white',
    };
  }
  const tomorrow = moment(i.dueDate).add(1, 'day');
  if (tomorrow.isBefore(moment())) {
    const count = countDays(i.dueDate, undefined);
    return {
      text: 'Forfalt',
      subtext: 'Forfalt for ' + count + ' dager siden',
      color: 'error',
      textColor: 'black',
      state: 'overdue',
    };
  }
  return {
    text: 'Ikke forfalt',
    subtext: 'Forfaller ' + (dueInDays > 1 ? 'om ' + dueInDays + ' dager' : 'i morgen'),
    color: 'orange',
    textColor: 'black',
    state: 'not_overdue',
  };
}

export function lineSumWithoutVat(line) {
  const result = chain(line.quantity ?? 0)
    .multiply(getDiscountedPrice(line))
    .done();
  return isNaN(result) || !result ? 0 : result;
}

export function getDiscountedPrice(line) {
  let discountMultiplier = 1;
  if (line.discount) {
    discountMultiplier = chain(100 - line.discount)
      .multiply(0.01)
      .done();
  }

  return chain(line.unitPrice || 0)
    .multiply(discountMultiplier)
    .done();
}

export function getVatMultiplier(value, VATList, key = 'code') {
  const vat = VATList.find((v) => v[key] === value);
  if (value && vat) {
    return vat.rate * vat.proportionalPercentage;
  }
  return 0;
}

export function getProductTypeText(value) {
  if (value) return productTypes.find((v) => v.value === value).text;
  return '';
}

export function getOnboardStepText(value) {
  if (value) return onboardSteps.find((v) => v.value === value).text;
  return '';
}

export function getModuleInfo(value) {
  if (value) return modules.find((v) => v.value === value);
  return null;
}

export function isRemoveLogoModule(module) {
  return module && module.name === constants.modules.brandingRemoval;
}

export function isVerifyEmailStep(step) {
  return step && step.name === 'verify_email';
}

export function chunk(arr, len) {
  const chunks = [];
  let i = 0;
  const n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}

export function isAddBankAccountStep(step) {
  return step && step.name === 'add_bank_account';
}

export function isInitInvoiceNumberStep(step) {
  return step && step.name === 'init_invoice_number';
}

export function isCreateFirstInvoiceStep(step) {
  return step && step.name === 'create_first_invoice';
}

export function countLines(str) {
  return str.split(/\r\n|\r|\n/).length;
}

export function randomString(length = 10) {
  let id = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    id += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return id;
}

export function downloadBlob(blob, name, mediaType) {
  const reader = new FileReader();
  reader.onload = () => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = `data:${mediaType};base64,${btoa(reader.result)}`;
    link.setAttribute('download', name);
    link.click();
    document.body.removeChild(link);
  };
  reader.readAsBinaryString(blob);
}

export function getInvoicePdf(attachments) {
  if (attachments) {
    return attachments.find((a) => a.type === 'GENERATED');
  }
  return null;
}

export function getLastInvoiceReminder(attachments) {
  let invoiceReminder = null;
  if (attachments) {
    for (let i = 0; i < attachments.length; i++) {
      if (
        attachments[i].type === 'INVOICE_REMINDER' &&
        (invoiceReminder === null || moment(attachments[i].createdAt) > moment(invoiceReminder?.createdAt))
      ) {
        invoiceReminder = attachments[i];
      }
    }
  }

  return invoiceReminder;
}

export function getNotificationRoute(notification, currentCompanyNameSlug) {
  if (notification && notification.action) {
    switch (notification.action.toLowerCase()) {
      case 'open_invoices':
        return { name: 'Invoices', params: { companyNameSlug: currentCompanyNameSlug } };
    }
  }
  return null;
}

export function preventNonDigitKeys(e) {
  return /^[0-9]+$/.test(e.key) || e.preventDefault();
}

export function preventNonNumericKeys(e) {
  return /^[e\-.,+]$/.test(e.key) && e.preventDefault();
}

export function preventKeys(e, keys) {
  if (!keys) keys = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+-!"#¤%&/()=?"'.split('');
  return keys.includes(e.key) && e.preventDefault();
}

export function preventKeysAllowNegative(e, keys) {
  return preventKeys('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+!"#¤%&/()=?"');
}

export function getFileExtension(fileName) {
  const index = (fileName || '').lastIndexOf('.');
  return index >= 0 ? fileName.slice(index + 1).toUpperCase() : '';
}

export function isImage(mediaType) {
  return mediaType && mediaType.startsWith('image/');
}

export function isPdf(mediaType) {
  return mediaType === 'application/pdf';
}

export function tableSorter(items, sortBy, sortDesc) {
  return sortItems(items, sortBy, sortDesc, constants.sortLocale);
}

export function tableStringNumericSorter(items, sortBy, sortDesc) {
  const result = items.slice().sort((a, b) => {
    if (typeof a[sortBy[0]] === 'boolean') {
      return a[sortBy[0]] - b[sortBy[0]];
    }
    return a[sortBy[0]]?.localeCompare(b[sortBy[0]], undefined, { numeric: true });
  });
  if (sortDesc[0]) return result.reverse();
  return result;
}

// sorting for tables, doesn't support multiple sort but we don't use it at all, so only sortBy[0] and sortDesc[0] used
// items: any[], sortBy: string[], sortDesc: boolean[]
// output is sorted array, numbers first, then alphabetic, then norwegian letters (å, ø, etc)
export function numbersAlphabeticSort(items, sortBy, sortDesc) {
  const nestedSort = sortBy[0]?.indexOf('.') !== -1;
  if (nestedSort) return tableSorter(items, sortBy, sortDesc);

  return items.sort((a, b) => {
    const aVal = a[sortBy[0]] === undefined ? '' : a[sortBy[0]] === null ? '' : a[sortBy[0]];
    const bVal = b[sortBy[0]] === undefined ? '' : b[sortBy[0]] === null ? '' : b[sortBy[0]];

    if (aVal === '' && bVal !== '') {
      return sortDesc[0] ? 1 : -1;
    } else if (aVal !== '' && bVal === '') {
      return sortDesc[0] ? -1 : 1;
    } else if (aVal === '' && bVal === '') {
      return 0;
    }

    const aIsNumber = !isNaN(aVal);
    const bIsNumber = !isNaN(bVal);

    if (aIsNumber && bIsNumber) {
      return (sortDesc[0] ? -1 : 1) * (parseFloat(aVal) - parseFloat(bVal));
    } else if (aIsNumber) {
      return sortDesc[0] ? 1 : -1;
    } else if (bIsNumber) {
      return sortDesc[0] ? -1 : 1;
    } else {
      const sortResult = aVal.localeCompare(bVal, constants.sortLocale, { numeric: true, sensitivity: 'base' });
      return (sortDesc[0] ? -1 : 1) * sortResult;
    }
  });
}

export function removeEmpty(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => (v && typeof v === 'object' ? removeEmpty(v) : v)).filter((v) => v != null && v !== '');
  } else {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null && v !== '')
        .map(([k, v]) => [k, typeof v === 'object' ? removeEmpty(v) : v]),
    );
  }
}

export function removeZeroAndNegative(obj) {
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === 'object' ? removeZeroAndNegative(v) : v))
      .filter((v) => typeof v != 'number' || v > 0);
  } else {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => typeof v != 'number' || v > 0)
        .map(([k, v]) => [k, typeof v === 'object' ? removeZeroAndNegative(v) : v]),
    );
  }
}

export function handleError(err) {
  if (err instanceof Error) {
    // API errors are plain objects and have already been reported by the API.
    Sentry.captureException(err);
  }
}

export function isEmpty(obj) {
  if (obj === '' || obj === null || JSON.stringify(obj) === '{}' || JSON.stringify(obj) === '[]' || obj === undefined) {
    return true;
  } else {
    return false;
  }
}

export function rmEmpty(o) {
  if (typeof o !== 'object') {
    return o;
  }
  const oKeys = Object.keys(o);
  for (let j = 0; j < oKeys.length; j++) {
    const p = oKeys[j];
    switch (typeof o[p]) {
      case 'object':
        if (Array.isArray(o[p])) {
          for (let i = 0; i < o[p].length; i++) {
            o[p][i] = rmEmpty(o[p][i]);
            if (isEmpty(o[p][i])) {
              o[p].splice(i, 1);
              i--;
            }
          }
          if (o[p].length === 0) {
            if (Array.isArray(o)) {
              o.splice(parseInt(p), 1);
              j--;
            } else {
              delete o[p];
            }
          }
        } else {
          if (isEmpty(o[p])) {
            delete o[p];
          } else {
            o[p] = rmEmpty(o[p]);
            if (isEmpty(o[p])) {
              delete o[p];
            }
          }
        }
        break;
      default:
        if (isEmpty(o[p])) {
          delete o[p];
        }
        break;
    }
  }
  if (Object.keys(o).length === 0) {
    return;
  }
  return o;
}
export function getMonths(short) {
  const monthNames = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];
  if (short) {
    return moment.monthsShort();
  } else {
    return monthNames;
  }
}

export function getTrekkode(employmentType) {
  switch (employmentType) {
    case 'ALLOWANCE':
      return 'Introduksjonsstoenad';
    case 'MAIN':
      return 'Lønn fra hovedarbeidsgiver';
    case 'SECONDARY':
      return 'Lønn fra biarbeidsgiver';
    case 'NAV':
      return 'Lønn fra NAV';
    case 'INSURANCE_NAV':
      return 'Uføretrygd fra NAV';
    case 'PENSION':
      return 'Pensjon';
    case 'PENSION_NAV':
      return 'Pensjon fra NAV';
    case 'DISABILITY_PENSION_OTHER':
      return 'Uføreytelser fra andre';
    default:
      return 'Annen';
  }
}

export function getTrekType(taxCardType) {
  switch (taxCardType) {
    case 'PERCENTAGE_BASED':
      return 'Prosenttrekk';
    case 'TABLE_BASED':
      return 'Tabelltrekk';
    default:
      return 'Annen';
  }
}

// Returns a string like "[1-6]p[year]y"
export function getDefaultPeriod(includePeriod = true) {
  const today = new Date();

  return getPeriodFromDate(today, includePeriod);
}

export function getPeriodFromDate(date, includePeriod = true) {
  const year = date.getFullYear();
  if (!includePeriod) {
    // Return only the year with 'y' suffix if period is not to be included
    return `${year}y`;
  }
  const month = date.getMonth();
  const biMonthlyPeriod = Math.floor(month / 2) + 1;

  return `${biMonthlyPeriod}p${year}y`;
}

export function getDateFromPeriod(period) {
  const periodRegex = /((\d)p)?(\d{4})y/;

  if (!periodRegex.test(period)) throw new Error('Invalid period format');

  const [periodNum, year] = period.match(periodRegex).slice(2, 4);
  let dateFrom, dateTo;

  if (periodNum) {
    const monthFrom = (parseInt(periodNum, 10) - 1) * 2;
    const monthTo = monthFrom + 1;
    dateFrom = new Date(year, monthFrom, 1);
    dateTo = new Date(year, monthTo + 1, 0); // Set to last day of monthTo
  } else {
    // If the period is just the year "2023y"
    dateFrom = new Date(year, 0, 1); // January 1st of the year
    dateTo = new Date(year, 11, 31); // December 31st of the year
  }
  return { dateFrom, dateTo };
}
