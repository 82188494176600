<template>
  <v-data-table
    :items-per-page="-1"
    :headers="headers"
    :items="items"
    :loading="loading"
    :value="selection"
    sort-by="uploadedAtSort"
    sort-desc
    :custom-sort="numbersAlphabeticSort"
    item-key="id"
    :show-select="showSelect"
    checkbox-color="primary"
    :item-class="isSelected"
    no-data-text="Ingen flere bilag til godkjenning"
    hide-default-footer
    v-bind="$attrs"
    @input="$emit('change:selection', $event)"
    @click:row="rowClicked"
  >
    <template #item.preview="{ item }">
      <v-btn v-if="item.fileId" icon @click.stop="$emit('openPDFPreview', item)">
        <v-icon small>mdi-eye</v-icon>
      </v-btn>
    </template>
    <template #item.uploadedAtSort="{ item }">
      <div>{{ formatDate(item.uploadedAt) }}</div>
    </template>
    <template #item.actions="{ item }">
      <v-btn v-if="showChangeStatus" outlined small color="success" @click.stop="$emit('change:status', { item })"
        >Godkjenn</v-btn
      >
      <v-btn icon class="ml-4" @click.stop="$emit('edit', item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-menu left>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$emit('download', item)">
            <v-list-item-icon>
              <v-icon>mdi-download</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Last ned</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$emit('delete', { item })">
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Slett</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type { DataTableHeader } from 'vuetify';
import type { InboxFile } from '../accounting/models/InboxFile';
import { numbersAlphabeticSort, formatDate } from '@/utils/shared';

export default defineComponent({
  model: {
    prop: 'selection',
    event: 'change:selection',
  },
  props: {
    items: {
      type: Array as PropType<InboxFile[]>,
      default: () => [],
    },
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
    },
    rowClicked: {
      type: Function,
      default: () => {},
    },
    selection: {
      type: Array as PropType<InboxFile[]>,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showChangeStatus: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['delete', 'edit', 'download', 'change:selection', 'change:status', 'openPDFPreview'],
  computed: {
    fileId() {
      return this.$route.query.fileId;
    },
  },
  methods: {
    formatDate,
    numbersAlphabeticSort,
    isSelected(item: InboxFile): string {
      return item.fileId === this.fileId ? 'focus' : '';
    },
  },
});
</script>
